<template>
	<div class="customerHome customerOrder">

		<Tabs :value="activeTab" @on-click='changeTabs'>
			<TabPane label="我要定制" name="setOrder">
				<div class="chuck top">
					<div class="tab-1">
						<div v-if="!noValueInteres" class="processImg process1">
							<Process :data='processOne'></Process>
						</div>
						<div v-else class="processImg process2">
							<Process :data='processTwo'></Process>
						</div>
					</div>
				</div>
				<div class="chuck contentBox">
					<div class="btnBox">
						<div @click="btn = 1" :class="['btn' ,'btn2',btn == 1 ? 'active' : '']">开发定制</div>
						<div @click="btn = 0" style="margin-left: -1px;"
							:class="['btn' ,'btn1',btn == 0 ? 'active' : '']">数据定制</div>
					</div>
					<Form ref="customerForm" style="margin-top: 20px;" :model="customerForm" :rules="acustomerFormRules"
						:label-width="120" label-colon inline>
						<FormItem style='width: 30%;' prop="requireName" label="需求/项目名称">
							<Input maxlength="30" show-word-limit type="text" placeholder="需求/项目名称"
								v-model="customerForm.requireName" />
						</FormItem>
						<FormItem style='width: 30%;' prop="requireType" label="类型">
							<Select placeholder="需求/需求类型" v-model="customerForm.requireType" clearable>
								<Option v-for="item in typeList" :key="item.code" :value="item.name">{{item.name}}
								</Option>
							</Select>
						</FormItem>
						<FormItem style='width: 30%;' prop="requireEmail" label="邮箱">
							<Input type="text" placeholder="邮箱" v-model="customerForm.requireEmail" />
						</FormItem>
						<br>
						<FormItem style='width: 91.3%;' prop="requireText" label="描述">
							<Input maxlength="1000" show-word-limit :rows="15" type="textarea" placeholder="描述"
								v-model="customerForm.requireText" />
						</FormItem>
						<br>
						<FormItem prop="requireFileUrl" label="上传附件">
							<UploadFile ref="UploadFile" info='上传附件' @removeFile='removeFile'
								@handleSuccess='handleSuccessFile'>
							</UploadFile>
						</FormItem>
						<FormItem style='width: 100%'>
							<ComfirmButton :customerStyle="customerStyle" ref="ComfirmButton" type='primary'
								@comfirm='submit("customerForm")' info='提交'>
								<div slot="content">
									<div class="sumBox">
										确定提交？
									</div>
								</div>
							</ComfirmButton>
						</FormItem>
					</Form>
				</div>
			</TabPane>
			<TabPane label="我的定制" name="getOrder">
				<div class="chuck top">
					<div class="tab-2">
						<Form ref="searchForm" :model="searchForm" :label-width="120" label-colon inline>
							<FormItem style='width: 30%;' prop="requireName" label="需求/项目名称">
								<Input type="text" placeholder="需求/项目名称" v-model="searchForm.requireName" />
							</FormItem>
							<FormItem style='width: 30%;' prop="requireUid" label="编号">
								<Input type="text" placeholder="编号" v-model="searchForm.requireUid" />
							</FormItem>
							<FormItem style='width: 30%;' prop="requireType" label="类型">
								<Select placeholder="需求类型" v-model="searchForm.requireType" clearable>
									<Option v-for="item in allTypeList" :key="item.code" :value="item.name">
										{{item.name}}
									</Option>
								</Select>
							</FormItem>
							<FormItem style='width: 30%;' prop="progressStatus" label="进展">
								<Select placeholder="进展" v-model="searchForm.progressStatus" clearable>
									<Option v-for="item in allProgressList" :key="item.code" :value="item.code">
										{{item.name}}
									</Option>
								</Select>

							</FormItem>
							<FormItem style='width: 30%;' label="更新时间">
								<DatePicker @on-change='selectTime' type="daterange" :editable='false' transfer
									separator='至' show-week-numbers placement="bottom-end" placeholder="开始时间-截止时间"
									format='yyyy-MM-dd' style="width: 100%"></DatePicker>
							</FormItem>
							<FormItem style='width: 30%;'>
								<Button class="searchBtnLog" data-id="1" style="width: 140px;height: 40px;" type="primary" @click="search()">查询</Button>
							</FormItem>
						</Form>
					</div>
				</div>
				<div class="chuck contentBox">
					<div class="tableTitle">

						<div class="tableItem left titleInfo">
							需求/项目列表
						</div>

						<div class="tableItem right">
							<ComfirmButton :width='970' :ComfirmBoxStyle="intersBtn" :customerStyle="intersBtn2"
								v-if="noInteres" :title="null" ref="interestsPackageBtn" :showCancle='false'
								type='default' info='我的权益'>
								<div slot="content">
									<div style="padding: 0px 30px;" class="interestsPackageBtnBox">
										<Button style="width: 140px;height: 40px;margin-top: 30px;" type="primary"
											@click="goUrl('/customerorder/interestspackage')">使用记录</Button>
										<Table style="margin-top: 20px;padding-bottom: 60px;" no-data-text='暂无定制需求/项目权益'
											stripe :columns="configDetailColumns" :data="configDetailArray"></Table>

									</div>
								</div>
							</ComfirmButton>
						</div>


					</div>
					<Table :columns="customerColums" :data="customerData">
						<template slot-scope="{ row }" slot="action">
							<div>
								<div class="btns" v-for="(btn,index) in row.button.number" :key="`${index}-${btn}`">
									<!-- 结案 -->
									<ComfirmButton size='small' v-if="btn == 5" :type=btn|btnType
										@comfirm='action(row,btn)' :info='btn|btnName'>
										<div slot="content">
											<div class="nomalBox">
												<p class="text">确认结案？</p>
											</div>
										</div>
									</ComfirmButton>
									<!-- 项目工作量 -->
									<ComfirmButton :ref="`ComfirmButton-${row.id}`" size='small' v-else-if="btn == 9"
										:type="btn|btnType" :showCancle='false' @comfirm='action(row,btn)'
										:info='btn|btnName'>
										<div slot="content">
											<div class="nomalBox">
												<p class="text">项目工作量:{{row.evaluateWorkload}}人/天</p>
											</div>
										</div>
									</ComfirmButton>
									<Button size='small' v-else :type=btn|btnType
										@click="action(row,btn)">{{btn|btnName}}</Button>
								</div>
							</div>
						</template>
					</Table>
					<Page style='margin-top: 30px;' :page-size='size' :current='current' :total="total"
						@on-change='pageChange' />
				</div>

			</TabPane>
		</Tabs>
	</div>
</template>

<script>
	import {
		data_page,
		data_submit,
		data_types,
		data_config_detail,
		data_processStatus,
		data_detail_downloadUrl,
		data_close,
		data_config_hasConfig,
		data_config_hasValueConfig
	} from "@/api/customerApi.js";
	import UploadFile from '../../components/UploadFile.vue'
	import ComfirmButton from '../../components/Comfirm.vue'
	import Process from '../../components/Process/Process.vue'
	import util from '../../utils/utils2.js'
	import dataReports from "@/mixins/dataReports";
	let VUEAPP
	export default {
		name: 'CustomerOrder',
		components: {
			UploadFile,
			ComfirmButton,
			Process
		},
		beforeCreate() {
			VUEAPP = this
		},
		filters: {
			btnName(e) {
				let str
				VUEAPP.btnList.forEach(btn => {
					if (btn.code == e) {
						str = btn.name
					}
				})
				return str
			},
			btnType(e) {
				let str
				VUEAPP.btnList.forEach(btn => {
					if (btn.code == e) {
						str = btn.type
					}
				})
				return str
			}
		},
		mixins: [dataReports],
		data() {
			return {
				btnList: [{
						code: 0,
						name: '查看',
						type: 'primary'
					}, {
						code: 1,
						name: '补充需求',
						type: 'primary'
					}, {
						code: 2,
						name: '需求确认',
						type: 'warning',
					}, {
						code: 3,
						name: '查看评估',
						type: 'primary'
					}, {
						code: 4,
						name: '下载成果',
						type: 'warning',
					}, {
						code: 5,
						name: '结案',
						type: 'warning',
					}, {
						code: 6,
						name: '用户评价',
						type: 'primary'
					}, {
						code: 7,
						name: '管理员确认开发',
						type: 'primary'
					}, {
						code: 8,
						name: '查看评价',
						type: 'primary'
					},
					{
						code: 9,
						name: '项目工作量',
						type: 'primary'
					},
				],
				activeTab: 'setOrder',
				submitLoading: false,
				noInteres: false,
				noValueInteres: false,
				tab: 1,
				btn: 1,
				typeList: [],
				configDetail: {},
				configDetailArray: [],
				configDetailColumns: [{
					title: '权益次数',
					key: 'requireSum',
				}, {
					title: '权益工作量（人/天）',
					key: 'requireWorkloadSum',
				}, {
					title: '起始日期',
					key: 'dateStart',
					render: (h, params) => {
						return h(
							"div",
							util.formatDateFmt(
								new Date(params.row.dateStart),
								"yyyy-MM-dd"
							)
						);
					},
				}, {
					title: '截止日期',
					key: 'dateEnd',
					render: (h, params) => {
						return h(
							"div",
							util.formatDateFmt(
								new Date(params.row.dateEnd),
								"yyyy-MM-dd"
							)
						);
					},
				}, ],

				customerForm: {
					requireName: '',
					requireType: '',
					requireEmail: '',
					requireText: '',
					requireFileUrl: '',
					requireFileSize: '',
					requireFileName: '',
				},
				acustomerFormRules: {
					requireName: [{
						required: true,
						message: '必填项',
					}],
					requireType: [{
						required: true,
						message: '必选项',
					}],
					requireText: [{
						required: true,
						message: '必填项',
					}],
				},
				customerStyle: {
					'width': '140px',
					'height': '40px',
				},
				searchForm: {
					requireName: '',
					requireUid: '',
					requireType: '',
					progressStatus: '',
					gmtUpdatedStart: '',
					gmtUpdatedEnd: '',
				},
				allTypeList: [],
				allProgressList: [],
				selectedTime: [],
				size: 10,
				current: 1,
				total: 1,
				customerData: [],
				customerColums: [{
					title: '编号',
					key: 'requireUid',
					width: 150,
				}, {
					title: '需求/项目名称',
					key: 'requireName',
					width: 200,
					tooltip: true,
				}, {
					title: '类型',
					key: 'requireType',
					width: 150,
				}, {
					title: '提交日期',
					key: 'gmtCreate',
					width: 150,
					render: (h, params) => {
						return h(
							"div",
							util.formatDateFmt(
								new Date(params.row.gmtCreate),
								"yyyy-MM-dd"
							)
						);
					},
				}, {
					title: '评估回复时间',
					key: 'requireRespTime',
					width: 200,
					render: (h, params) => {
						return h(
							"div",
							util.formatDateFmt(
								new Date(params.row.requireRespTime),
								"yyyy-MM-dd hh:mm:ss"
							)
						);
					},
				}, {
					title: '进展',
					key: 'requireProgress',
					width: 150,
				}, {
					title: '更新时间',
					key: 'gmtUpdated',
					width: 200,
					render: (h, params) => {
						return h(
							"div",
							util.formatDateFmt(
								new Date(params.row.gmtUpdated),
								"yyyy-MM-dd hh:mm:ss"
							)
						);
					},
				}, {
					title: '结案时间',
					key: 'requireCloseTime',
					width: 200,
					render: (h, params) => {
						return h(
							"div",
							util.formatDateFmt(
								new Date(params.row.requireCloseTime),
								"yyyy-MM-dd hh:mm:ss"
							)
						);
					},
				}, {
					title: '操作',
					key: 'action',
					slot: 'action',
					width: 400,
					fixed: "right",
					align: "center",
				}, ],

				intersBtn: {
					'float': 'right',
				},
				intersBtn2: {
					'width': '120px',
					'height': '40px'
				},
				processOne: ['1.提交需求', '2.需求评估', '3.需求确认', '4.签订意向书', '5.付定金', '6.立项', '7.项目开发', '8.交付验收', '9.结案'],
				processTwo: ['1.提交需求', '2.需求评估', '3.需求确认', '4.立项', '5.项目开发', '6.交付验收', '7.结案']
			}
		},
		watch: {
			btn(val) {
				this.getTypes(val)
				this.$refs.customerForm.resetFields()
			},
			activeTab(val) {
				if (val == 'getOrder') {
					this.getCustomPage(1)
				}
			}

		},
		computed: {
			customerInfo() {
				let str = localStorage.getItem('customerInfo')
				return JSON.parse(str)
			},
		},
		mounted() {
			this.init()
			if (this.$route.query.tab && this.$route.query.tab == 2) {
				this.activeTab = 'getOrder'
			}
		},
		methods: {
			init() {
				this.getTypes(1)
				this.getAllTypes()
				this.getAllProgressList()
				this.getConfigDetail()
				this.getValueConfigDetail()
			},
			changeTabs(e) {
				this.activeTab = e
				if (e == 'getOrder') {
					this.getCustomPage(1)
				}
			},
			async getCustomPage(current) {
				let params = {}
				for (let key in this.searchForm) {
					if (this.searchForm[key] !== '') {
						params[key] = this.searchForm[key]
					}
				}
				params.current = current
				params.size = this.size
				let data = await data_page(params)
				this.current = data.current
				this.size = data.size
				this.total = data.total
				this.customerData = data.records
			},
			async getConfigDetail() {
				let data2 = await data_config_hasConfig({
					customerId: this.customerInfo.id
				})
				this.noInteres = data2
				//有权益
				if (data2) {
					let data = await data_config_detail({
						customerId: this.customerInfo.id
					})
					this.configDetailArray = data
				}
			},
			async getValueConfigDetail() {
				let data = await data_config_hasValueConfig({
					customerId: this.customerInfo.id
				})
				this.noValueInteres = data
				//有权益
			},

			async getTypes(type) {
				let data = await data_types({
					type: type,
					requireOrigin: 1,
				})
				this.typeList = data

			},
			async getAllTypes() {
				let data = await data_types({
					type: '',
					requireOrigin: 1,
				})
				this.allTypeList = data
			},
			async getAllProgressList() {
				let data = await data_processStatus()
				this.allProgressList = data
			},
			handleSuccessFile(res) {
				this.customerForm.requireFileUrl = res.url
				this.customerForm.requireFileSize = res.size
				this.customerForm.requireFileName = res.name
			},
			removeFile() {
				this.customerForm.requireFileUrl = ''
			},
			submit(name) {
				this.$refs[name].validate(async (valid) => {
					if (valid) {
						let params = this.customerForm
						params.requireOrigin = 1
						this.submitLoading = true
						let data = await data_submit(params)
						if (data === true) {
							this.$msg.success({
								text: "提交成功"
							})
							this.$refs.ComfirmButton.cancle()
							this.$refs[name].resetFields()
							this.submitLoading = false
							this.activeTab = 'getOrder'
							this.$refs.UploadFile.removeFile()
						} else {
							this.$msg.error({
								text: data
							})
							this.submitLoading = false
						}
					} else {
						this.$msg.error({
							text: "请输入必填项"
						})
						this.cancle()
					}
				})

			},
			cancle(name) {
				if (name) {
					this.$refs[name].cancle()
				} else {

					this.$refs.ComfirmButton.cancle()
				}
			},
			search() {
				this.getCustomPage(1)
			},
			pageChange(e) {
				this.getCustomPage(e)
			},
			selectTime(e) {
				this.searchForm.gmtUpdatedStart = e[0]
				this.searchForm.gmtUpdatedEnd = e[1]
			},
			goUrl(url, params) {
				this.$router.push({
					path: url,
					query: params ? params : ''
				})
			},
			action(row, actionType) {
				//调用对应的btn方法
				this[`btn${actionType}`](row)
			},
			//查看
			btn0(row) {
				this.goUrl('/customerorder/customerorderread', {
					requireUid: row.requireUid
				})
			},
			//补充需求
			btn1(row) {
				this.goUrl('/customerorder/customerordersupplement', {
					requireUid: row.requireUid
				})
			},
			//需求确认
			btn2(row) {
				this.goUrl('/customerorder/customerorderassessment', {
					requireUid: row.requireUid
				})
			},
			//查看评估
			btn3(row) {
				this.goUrl('/customerorder/customerorderassessmentread', {
					requireUid: row.requireUid
				})
			},
			//下载成果
			async btn4(row) {
				this.$btnSysLog(1,1)
				data_detail_downloadUrl({
					requireUid: row.requireUid
				}).then(res => {
					if (res) {
						window.open(res)
					} else {
						this.$msg.error({
							text: "暂无成果"
						})
					}
				})
			},
			//结案
			async btn5(row) {
				data_close({
					requireUid: row.requireUid
				}).then(res => {
					this.$msg.success({
						text: "结案成功！"
					})
					setTimeout(() => {
						this.btn6(row)
					}, 500)
				}).catch(err => {
					this.$msg.error({
						text: err
					})
				})
			},
			//反馈评价
			btn6(row) {
				this.goUrl('/customerorder/customerorderevaluation', {
					requireUid: row.requireUid,
				})
			},
			//管理员确认开发(缺失，不做)
			btn7(row) {
			},
			//查看评价
			btn8(row) {
				this.goUrl('/customerorder/customerorderevaluation', {
					requireUid: row.requireUid,
				})
			},
			//项目工作量
			btn9(row) {
			},
		}
	}
</script>

<style lang="scss" scoped>
	@import "@/style/customerorder.scss";

	.top {
		font-size: 0;
		z-index: 10;

		.processImg {
			position: relative;
		}

		.tab-1 {
			padding-top: 24px;
			padding-bottom: 24px;
		}

		.tab-2 {
			font-size: 14px;
			padding-top: 30px;
		}
	}

	.tabBox {
		background: transparent;
		box-shadow: none;
		padding-bottom: 0px;
		z-index: 11;
		padding-top: 0;

		.tab {
			display: inline-block;
			width: 180px;
			height: 56px;
			font-size: 20px;
			font-family: Source Han Sans CN, Source Han Sans CN-Regular;
			font-weight: 400;
			text-align: center;
			color: #333333;
			line-height: 56px;
			opacity: 0.5;
			background: rgba(235, 235, 235, 0.00);
			border: 1px solid #c8c8c8;
			border-radius: 4px 4px 0px 0px;
			margin-left: 20px;
			cursor: pointer;

			&.active {
				font-size: 20px;
				font-family: Source Han Sans CN, Source Han Sans CN-Medium;
				font-weight: 500;
				color: #00b6ff;
				line-height: 56px;
				background: #FFFFFF;
				opacity: 1;
				border: none;
			}
		}
	}

	.contentBox {
		margin-top: 20px;
		min-height: 69vh;
		padding: 20px 50px;

		.btnBox {
			width: 300px;
			height: 40px;
			cursor: pointer;

			.btn {
				display: inline-block;
				width: 120px;
				height: 40px;
				background-repeat: no-repeat;
				background-position: center;
				background-size: 100% auto;
				font-size: 18px;
				text-align: center;
				line-height: 40px;
				border: 1px solid #00b6ff;
				color: #00b6ff;
				background-color: rgba($color: #cccccc, $alpha: 0.05);

				&.active {
					color: #FFFFFF;
					background-color: rgba($color: #00b6ff, $alpha: 1);
				}

				&.btn1 {
					border-bottom-right-radius: 10px;
					border-top-right-radius: 10px;
				}

				&.btn2 {
					border-bottom-left-radius: 10px;
					border-top-left-radius: 10px;
				}

			}
		}
	}

	.nomalBox {
		text-align: center;
		width: 100%;
		height: 120px;
		line-height: 160px;

		.text {
			font-size: 18px;
		}

		.btn {
			display: inline-block;
			width: 140px;
			height: 40px;
		}
	}

	.sumBox {
		text-align: center;
		width: 100%;
		height: 120px;
		line-height: 160px;
		// padding-top: 80px;
		font-size: 18px;

		.btn {
			display: inline-block;
			width: 140px;
			height: 40px;
		}
	}

	.btns {
		display: inline-block;
		margin-left: 2px;
	}

	.tableTitle {
		width: 100%;
		position: relative;
		display: flex;

		.tableItem {
			flex: 1;
			margin-bottom: 20px;
		}


		.titleInfo {
			display: inline-block;
			font-size: 18px;
			line-height: 40px;
		}
	}

	/deep/ .ivu-tabs-tab {
		font-size: 20px;

	}

	/deep/ .ivu-tabs-ink-bar {
		height: 4px;
	}
</style>
