<template>
	<div class="processItem">
		<div class="item info">
			<div class="text">
				{{info}}
			</div>
		</div>
		<div v-if="showIcon" class="item icon"></div>
	</div>
</template>

<script>
	export default {
		name: 'ProcessItem',
		props: ['info', 'showIcon']
	}
</script>

<style lang="scss" scoped>
	.processItem {
		position: relative;
		display: inline-block;
		font-size: 0;

		.item {
			display: inline-block;
			vertical-align: middle;
			font-size: #333333;
		}

		.info {
			padding: 10px;
			width: 125px;
			height: 40px;
			background: rgba($color: #d3effd, $alpha: 0.5);
			text-decoration: none;
			margin-left: 10px;
			.text {
				font-size: 16px;
				color: #1eaef5;
				text-align: center;
				line-height: 20px;
			}

			&::before {
				content: "";
				position: absolute;
				top: 0;
				left: 9px;
				width: 0;
				height: 0;
				border-left: 10px solid #FFFFFF;
				border-top: 20px solid transparent;
				border-bottom: 20px solid transparent;
			}

			&::after {
				content: "";
				position: absolute;
				top: 0;
				right: -10px;
				width: 0;
				height: 0;
				border-left: 10px solid rgba($color: #d3effd, $alpha: 0.5);
				border-top: 20px solid transparent;
				border-bottom: 20px solid transparent;
			}
		}

		@media screen and (max-width: 1544px) {
			.info {
				width: 109.37px !important;
				height: 35px !important;

				.text {
					font-size: 14px !important;
					line-height: 17.5px;
				}
				&::before {
					content: "";
					position: absolute;
					top: 0;
					left: 8.75px;
					width: 0;
					height: 0;
					border-left: 8.75px solid #FFFFFF;
					border-top: 17.5px solid transparent;
					border-bottom: 17.5px solid transparent;
				}
				
				&::after {
					content: "";
					position: absolute;
					top: 0;
					right: -8.75px;
					width: 0;
					height: 0;
					border-left: 8.75px solid rgba($color: #d3effd, $alpha: 0.5);
					border-top:  17.5px solid transparent;
					border-bottom:  17.5px solid transparent;
				}
			}
		}

		@media screen and (max-width: 1455px) {
			.info {
				width: 93.75px !important;
				height: 30px !important;

				.text {
					font-size: 12px !important;
					line-height: 12px;
				}
				&::before {
					content: "";
					position: absolute;
					top: 0;
					left: 7.5px;
					width: 0;
					height: 0;
					border-left: 7.5px solid #FFFFFF;
					border-top: 15px solid transparent;
					border-bottom: 15px solid transparent;
				}
				
				&::after {
					content: "";
					position: absolute;
					top: 0;
					right: -7.5px;
					width: 0;
					height: 0;
					border-left: 7.5px solid rgba($color: #d3effd, $alpha: 0.5);
					border-top: 15px solid transparent;
					border-bottom: 15px solid transparent;
				}
			}
		}



		.icon {
			width: 0px;
			height: 0px;
			margin: 0px;
			background: center center no-repeat;
			background-size: 100% auto;
			background-image: url(../../assets/image/customerOrder/arrow.png);
		}
	}
</style>
