<template>
	<div class="process">
	<ProcessItem v-for="(item,index) in data" :info='item' :showIcon='index != data.length-1' :key='index'></ProcessItem>
	</div>
</template>

<script>
	import ProcessItem from './ProcessItem.vue'
	export default {
		name:'process',
		components: {
			ProcessItem
		},
		props:['data']
	}
</script>

<style scoped>

</style>